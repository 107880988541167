 
import startsWith from 'lodash/startsWith';
import React, { FunctionComponent } from 'react';

import { MIME_TYPES } from '@savgroup-front-common/constants';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';

import messages from './messages';
import NoPreview from './NoPreview';

interface DocumentPreviewObjectProps {
  type?: string;
  url?: string;
  name?: string;
}

const ALLOWED_MIME_TYPES_FOR_OBJECT = [
  MIME_TYPES.JPEG,
  MIME_TYPES.PDF,
  MIME_TYPES.PNG,
  MIME_TYPES.PLAINT_TEXT,
  MIME_TYPES.GIF,
  MIME_TYPES.VIDEO_3GPP,
  MIME_TYPES.VIDEO_3GPP2,
  MIME_TYPES.VIDEO_MP4,
  MIME_TYPES.VIDEO_QUICKTIME,
  MIME_TYPES.IMAGE_HEIC,
  MIME_TYPES.IMAGE_HEIF,
  MIME_TYPES.IMAGE_BMP,
];

const DocumentPreviewObject: FunctionComponent<
  React.PropsWithChildren<DocumentPreviewObjectProps>
> = ({ type, url = '', name = '' }) => {
  const isVideo = startsWith(type, 'video') || type === MIME_TYPES.OCTET_STREAM;
  const isImage =
    type === MIME_TYPES.JPEG ||
    type === MIME_TYPES.PNG ||
    type === MIME_TYPES.GIF;
  const isAllowedObject = type
    ? ALLOWED_MIME_TYPES_FOR_OBJECT.includes(type as MIME_TYPES)
    : true;

  if (!type) {
    return <BaseLoader messageList={[messages.loading]} />;
  }

  if (isVideo) {
    return (
      <video src={url} controls>
        <NoPreview url={url} filename={name} />
      </video>
    );
  }

  if (!isAllowedObject) {
    return <NoPreview url={url} filename={name} />;
  }

  if (isImage) {
    return <img alt={name} src={url} />;
  }

  return (
    <>
      <object key={url} type={type || 'image/png'} data={url}>
        <NoPreview url={url} filename={name} />
      </object>
    </>
  );
};

DocumentPreviewObject.displayName = 'DocumentPreviewObject';

export default React.memo(DocumentPreviewObject);
