import { TABS_FILE } from '../../../constants/routing';
import {
  buildFilesUrl,
  buildOrdersUrl,
  buildSparePartRequestLinesUrl,
  buildStockItemUrl,
} from '../../../helpers';

import messages from './messages';
import { EmptyResponse, SearchResultGroup } from './Search/Search.types';

export const adaptSearchResults = ({
  response,
  primaryColor,
  cautionColor,
  infoColor,
  successColor,
}: {
  response: SearchResultGroup | EmptyResponse;
  primaryColor: { color: string; bgColor: string };
  cautionColor: { color: string; bgColor: string };
  infoColor: { color: string; bgColor: string };
  successColor: { color: string; bgColor: string };
}) => {
  const resultSearched = [];

  if (response.file.hitCount) {
    resultSearched.push({
      hits: response.file.hit?.map((hit) => ({
        searchId: hit.fileId,
        searchReference: hit.fileReference,
        searchLocalizedInfo: hit.localizedSolutionType,
        searchFirstName: hit.ownerFirstName,
        searchLastName: hit.ownerLastName,
        searchUrl: buildFilesUrl({
          fileId: hit.fileId,
          fileTabId: TABS_FILE.SUMMARY,
        }),
        highlights: hit.highlights,
      })),
      hitCount: response.file.hitCount,
      title: messages.files,
      icon: 'Folder',
      iconColor: primaryColor,
    });
  }

  if (response.order.hitCount) {
    resultSearched.push({
      hits: response.order.hit?.map((hit) => ({
        searchId: hit.orderId,
        searchReference: hit.orderReference,
        searchLocalizedInfo: hit.sellerName,
        searchFirstName: hit.firstName,
        searchLastName: hit.lastName,
        searchUrl: buildOrdersUrl({ orderId: hit.orderId }),
      })),
      hitCount: response.order.hitCount,
      title: messages.order,
      icon: 'Order',
      iconColor: cautionColor,
    });
  }

  if (response.sparePartRequest.hitCount) {
    resultSearched.push({
      hits: response.sparePartRequest.hit?.map((hit) => ({
        searchId: hit.requestLineId,
        searchReference: hit.sparePartReference,
        searchLocalizedInfo: hit.requestLineStatus,
        searchFirstName: hit.requesterName,
        searchLastName: `${hit.handlingType} ${hit.supplierName}`,
        searchUrl: buildSparePartRequestLinesUrl({
          requestLineId: hit.requestLineId,
        }),
      })),
      hitCount: response.sparePartRequest.hitCount,
      title: messages.sparePartRequest,
      icon: 'Sparepart',
      iconColor: infoColor,
    });
  }

  if (response.stockItem.hitCount) {
    resultSearched.push({
      hits: response.stockItem.hit?.map((hit) => ({
        searchId: hit.requestLineId,
        searchReference: hit.fileReference,
        searchLocalizedInfo: hit.sparePartReference,
        searchFirstName: hit.stockName,
        searchLastName: hit.supplierName,
        searchUrl: buildStockItemUrl({
          stockItemId: hit.stockItemId,
        }),
      })),
      hitCount: response.stockItem.hitCount,
      title: messages.stockItem,
      icon: 'Stock',
      iconColor: successColor,
    });
  }

  return resultSearched;
};
