import React, { Suspense } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { PERMISSIONS, ROLES } from '@savgroup-front-common/constants';
import { retryImportChunk } from '@savgroup-front-common/core/src/helpers/retryImportChunk';
import {
  useHasPermission,
  useHasSomeRoles,
} from '@savgroup-front-common/core/src/hooks';
import { useIsNewUiEnabled } from '@savgroup-front-common/core/src/hooks/useIsNewUiEnabled';
import BaseLoader from '@savgroup-front-common/core/src/molecules/BaseLoader/BaseLoader';
import useHasSettingsPagePermission from 'control/view/pages/settings/hooks/useHasSettingsPagePermission';

import { ROUTES } from '../../../constants/routing';
import useHasSellerSettingsPagePermission from '../../components/Settings/SellerSettings/SellerSettings.hooks';
import useHasBusinessIntelligencePermission from '../../pages/BusinessIntelligencePage/hooks/useHasBusinessIntelligencePermission';

import useEnvFavicon from './hooks/useEnvFavicon';
import useLoadAlerts from './hooks/useLoadAlerts';
import useLoadTodos from './hooks/useLoadTodos';
import messages from './messages';

const NotFoundPage = React.lazy(() =>
  retryImportChunk(() => import('../../pages/NotFoundPage/NotFoundPage')),
);
const SettingsPage = React.lazy(() =>
  retryImportChunk(() => import('../../pages/settings')),
);
const NewSettingsPage = React.lazy(() =>
  retryImportChunk(() => import('../../pages/NewSettings')),
);
const AlertsPage = React.lazy(() =>
  retryImportChunk(() => import('../../pages/AlertsPage')),
);
const FilesPage = React.lazy(() =>
  retryImportChunk(() => import('../../pages/FilesPage/FilesPage')),
);
const IssueAQuotationPage = React.lazy(() =>
  retryImportChunk(() => import('../../pages/IssueAQuotationPage')),
);

const FilesHistoricPage = React.lazy(() =>
  retryImportChunk(() => import('../../pages/FilesPage/FilesHistoricPage')),
);
const BulkChangesPage = React.lazy(() =>
  retryImportChunk(() => import('../../pages/BulkChangesPage')),
);
const TodosPage = React.lazy(() =>
  retryImportChunk(() => import('../../pages/TodosPage')),
);
const MarketplaceAggregator = React.lazy(() =>
  retryImportChunk(() => import('../../pages/MarketplaceAggregator')),
);
const OrderPage = React.lazy(() =>
  retryImportChunk(() => import('../../pages/orders')),
);
const OrderPageNewUi = React.lazy(() =>
  retryImportChunk(() =>
    import('../../pages/orders/OrderPageNewUi/OrderPageNewUi'),
  ),
);
const SparePartPage = React.lazy(() =>
  retryImportChunk(() => import('../../pages/sparePart')),
);
const BusinessIntelligencePage = React.lazy(() =>
  retryImportChunk(() => import('../../pages/BusinessIntelligencePage')),
);
const ConfigurationConsolePage = React.lazy(() =>
  retryImportChunk(() => import('../../pages/ConfigurationConsole')),
);

const BillingPage = React.lazy(() =>
  retryImportChunk(() => import('../../pages/BillingPage')),
);

const DebugInspectorPage = React.lazy(() =>
  retryImportChunk(() =>
    import('../../pages/ConfigurationConsole/DebugInspectorPage'),
  ),
);
const WelcomePage = React.lazy(() =>
  retryImportChunk(() =>
    import('../../pages/Onboarding/WelcomePage/WelcomePage'),
  ),
);

const FromScratch = React.lazy(() =>
  retryImportChunk(() =>
    import('../../pages/Onboarding/FromScratch/FromScratch'),
  ),
);

const IframeRoutes = React.lazy(() =>
  retryImportChunk(() => import('./IframeRoutes/IframeRoutes')),
);

const FilesPageNewUI = React.lazy(() =>
  retryImportChunk(() => import('../../pages/FilesPageNewUI/FilesPage')),
);
const TodosPageNewUI = React.lazy(() =>
  retryImportChunk(() => import('../../pages/FilesPageNewUI/FilesPage')),
);

const Routes = () => {
  const location = useLocation();

  useLoadAlerts();
  useLoadTodos();
  useEnvFavicon();

  const hasSettingsPagePermissions = Object.values(
    useHasSettingsPagePermission(),
  ).includes(true);
  const hasBusinessIntelligencePermission =
    useHasBusinessIntelligencePermission();

  const hasInspectorAccessRights = useHasPermission(
    PERMISSIONS.INSPECTOR_ACCESS_RIGHTS,
  );

  const isNewUiEnabled = useIsNewUiEnabled();

  const hasSellerSettingsPermissions = useHasSellerSettingsPagePermission();

  const hasUserManagementPermission = useHasPermission(
    PERMISSIONS.ROLE_MANAGEMENT,
  );
  const haseSanityTestsSettingsPermission = useHasPermission(
    PERMISSIONS.SANITY_TESTS,
  );
  const hasImportsPermission = useHasPermission(PERMISSIONS.IMPORT_MANAGEMENT);
  const hasConsoleAccessRightsPermission = useHasPermission(
    PERMISSIONS.CONSOLE_ACCESS_RIGHTS,
  );
  const isAdminDemo = useHasSomeRoles([
    ROLES.DEMO_ADMINISTRATOR,
    ROLES.ADMINISTRATOR,
  ]);

  const hasSomeSettingsPermissions =
    isAdminDemo ||
    hasSellerSettingsPermissions ||
    hasUserManagementPermission ||
    haseSanityTestsSettingsPermission ||
    hasImportsPermission ||
    hasConsoleAccessRightsPermission;

  return (
    <Suspense fallback={<BaseLoader messageList={[messages.pleaseWait]} />}>
      <Switch location={location.location}>
        <Route path={ROUTES.ALERTS_WITH_PARAMS}>
          <AlertsPage />
        </Route>
        {isNewUiEnabled ? (
          <Route path={ROUTES.TODOS_WITH_PARAMS}>
            <TodosPageNewUI />
          </Route>
        ) : (
          <Route path={ROUTES.TODOS_WITH_PARAMS}>
            <TodosPage />
          </Route>
        )}
        {isNewUiEnabled ? (
          <Route path={ROUTES.FILES}>
            <FilesPageNewUI />
          </Route>
        ) : (
          <Route path={ROUTES.FILES}>
            <FilesPage />
          </Route>
        )}
        <Route path={ROUTES.FILES_HISTORIC}>
          <FilesHistoricPage />
        </Route>
        <Route path={ROUTES.BULK_CHANGES}>
          <BulkChangesPage />
        </Route>
        <Route path={ROUTES.MESSAGES}>
          <MarketplaceAggregator />
        </Route>

        {isNewUiEnabled ? (
          <Route path={ROUTES.ORDERS}>
            <OrderPageNewUi />
          </Route>
        ) : (
          <Route path={ROUTES.ORDERS}>
            <OrderPage />
          </Route>
        )}

        <Route path={ROUTES.SPARE_PARTS}>
          <SparePartPage />
        </Route>
        <Route path={ROUTES.ISSUE_A_QUOTATION}>
          <IssueAQuotationPage />
        </Route>

        {!isNewUiEnabled && (
          <Route path={ROUTES.CONFIGURATION_CONSOLE}>
            <ConfigurationConsolePage />
          </Route>
        )}
        
        <Route path={ROUTES.BILLING}>
          <BillingPage />
        </Route>

        {!isNewUiEnabled && hasSettingsPagePermissions && (
          <Route path={ROUTES.SETTINGS}>
            <SettingsPage />
          </Route>
        )}

        {isNewUiEnabled && hasSomeSettingsPermissions && (
          <Route path={ROUTES.SETTINGS}>
            <NewSettingsPage />
          </Route>
        )}

        {hasBusinessIntelligencePermission && (
          <Route path={ROUTES.BUSINESS_INTELLIGENCE}>
            <BusinessIntelligencePage />
          </Route>
        )}
        {hasInspectorAccessRights && (
          <Route path={ROUTES.DEBUG_INSPECTOR}>
            <DebugInspectorPage />
          </Route>
        )}

        {isNewUiEnabled && (
          <Route path={ROUTES.FROM_SCRATCH}>
            <FromScratch />
          </Route>
        )}

        {isNewUiEnabled && (
          <Route path={ROUTES.WELCOME_PAGE}>
            <WelcomePage />
          </Route>
        )}

        <Route path={ROUTES.IFRAME} component={IframeRoutes} />

        <Redirect
          exact
          from={ROUTES.HOME}
          to={isNewUiEnabled ? ROUTES.WELCOME_PAGE : ROUTES.TODOS_WITH_PARAMS}
        />
        <Route path="*">
          <NotFoundPage />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default Routes;
